import styled, { createGlobalStyle } from 'styled-components';
import { color, container, fontSize, screen, shadow, spacing } from 'theme';

export const Fredon24GlobalStyles = createGlobalStyle`
  body {
    background-color: #EBF1FF;
    color: #1117D7;
    min-height: 100vh;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
`;

export const Root = styled.div`
  h1 {
    font-size: 128px;
    line-height: 1;
    position: relative;
    margin-bottom: 0.25rem;

    :after {
      content: '';
      display: inline-block;
      margin-left: 1.5rem;
      background: url('/images/fredcon24/plane.svg') no-repeat 0 0;
      width: 115px;
      height: 115px;
    }

    @media (max-width: ${screen('lg')}) {
      font-size: 75px;
    }

    @media (max-width: ${screen('md')}) {
      font-size: 50px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      margin-bottom: 0.75rem;

      :after {
        margin: 1rem auto 0 auto;
      }
    }
  }

  h1 span {
    display: block;
    font-weight: normal;
    font-size: ${fontSize('md')};
  }

  h2 {
    font-size: 50px;
    line-height: 1;
    position: relative;
    margin-bottom: 1.5rem;

    @media (max-width: ${screen('lg')}) {
      font-size: 42px;
    }

    @media (max-width: ${screen('md')}) {
      font-size: 28px;
    }
  }

  section {
    margin-bottom: ${spacing('xl4')};
  }
`;

export const GetTicketButton = styled.a`
  background-color: #1117d7;
  border: 2px solid #1117d7;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  padding: ${spacing('xs')} ${spacing('md')};
  border-radius: 35px;
  display: inline-flex;
  gap: 0.5rem;
  text-wrap: nowrap;

  &:focus,
  &:hover {
    color: #fff;
    border: 2px solid #becffe;
  }

  @media (max-width: ${screen('lg')}) {
    font-size: ${fontSize('xs')};
    gap: 0.15rem;
  }
`;

export const Container = styled.div`
  max-width: ${container('xl')};
  margin: auto;
  padding-left: ${spacing('md')};
  padding-right: ${spacing('md')};
`;

// Header -------
export const Header = styled.header`
  display: flex;
  flex-direction: column-reverse;
  gap: 0;
  margin-bottom: ${spacing('xl')};
`;

export const HeaderContainer = styled.div`
  max-width: ${container('xlMax')};
  margin: auto;
  padding-left: ${spacing('md')};
  padding-right: ${spacing('md')};
`;

export const HeaderNotice = styled.div`
  background-color: #becffe;
  font-size: ${fontSize('sm')};
  padding: ${spacing('sm')} 0;
`;

export const HeaderNav = styled.div`
  background-color: ${color('white')};
  box-shadow: ${shadow('md')};
  padding: ${spacing('sm')} 0;

  .nav a {
    color: #1117d7;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${screen('md')}) {
    .nav {
      display: none;
    }
  }

  @media (max-width: ${screen('md')}) {
    .nav {
      text-align: left;
    }

    .header {
      flex-direction: row;
    }
  }
`;

export const HeaderNavLogo = styled.a`
  img {
    height: 40px;
    display: block;
  }
`;
// /Header -------

// Hero -------
export const Hero = styled.section``;

export const HeroDesc = styled.div`
  font-size: ${fontSize('xl2')};
  margin-bottom: 1.5rem;

  @media (max-width: ${screen('md')}) {
    text-align: center;
  }
`;

export const HeroDate = styled.div`
  display: inline-block;
  background-color: #fff;
  padding: 0.75rem 1rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;

  @media (max-width: ${screen('md')}) {
  }
`;

export const HeroHost = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 0 0 31%;

  @media (max-width: ${screen('lg')}) {
    flex: 0 0 48%;
  }

  @media (max-width: ${screen('md')}) {
    flex: 0 0 100%;
  }

  img {
    height: 142px;
    width: 142px;

    @media (max-width: ${screen('lg')}) {
      height: 100px;
      width: 100px;
    }

    @media (max-width: ${screen('md')}) {
      height: 75px;
      width: 75px;
    }
  }

  strong {
    display: block;
    margin-bottom: 0.25rem;
  }
`;

export const HeroHostTBA = styled.div`
  background: #becffe;
  height: 142px;
  width: 142px;
  border-radius: 100%;

  @media (max-width: ${screen('lg')}) {
    height: 100px;
    width: 100px;
  }

  @media (max-width: ${screen('md')}) {
    height: 75px;
    width: 75px;
  }
`;
// /Hero -------

// What -------
export const What = styled.section`
  width: 65%;

  @media (max-width: ${screen('md')}) {
    width: 100%;
  }

  p {
    line-height: 1.75;

    @media (max-width: ${screen('lg')}) {
      line-height: 1.5;
    }
  }
`;

export const WhatDesc = styled.div``;

// /What -------

// Where -------
export const Where = styled.section`
  strong,
  span {
    display: block;
    margin-bottom: 0.5rem;
  }

  iframe {
    width: 65%;
    border-radius: 10px;
  }
`;

export const WhereDesc = styled.div``;

export const WhereWrapper = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: ${screen('md')}) {
    flex-direction: column;

    iframe {
      width: 100%;
    }
  }
`;
// /Where -------

// Agenda -------
export const Agenda = styled.section``;

export const AgendaItem = styled.div<{ isBreak: boolean }>`
  background-color: ${({ isBreak }) => (isBreak ? 'transparent' : '#BECFFE')};
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: ;
  padding: ${({ isBreak }) => (isBreak ? '0' : spacing('lg'))} ${spacing('lg')};
  gap: 1.5rem;

  @media (max-width: ${screen('md')}) {
    flex-direction: column;
    content-justify: flex-start;
    align-items: flex-start;
    gap: 0.75rem;
  }
`;

export const AgendaItemTime = styled.div`
  text-align: right;
  flex: 0 0 15%;

  @media (max-width: ${screen('md')}) {
    text-align: left;
  }
`;

export const AgendaItemContent = styled.div`
  strong {
    display: block;
    margin-bottom: 0.25rem;
  }
`;
// /Agenda -------

// Speakers -------
export const Speakers = styled.section``;

export const SpeakersDesc = styled.div`
  p {
    line-height: 1.75;

    @media (max-width: ${screen('lg')}) {
      line-height: 1.5;
    }
  }
`;

export const Speaker = styled.div`
  background-color: #becffe;
  border-radius: 10px;
  min-height: 352px;
  width: 232px;
  padding: ${spacing('md')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${screen('md')}) {
    width: auto;
    margin: 0 2rem;

    img {
      width: 100%;
    }
  }

  a {
    color: #1117d7;
  }

  strong {
    display: block;
    margin-bottom: 0.25rem;
  }
`;
// /Speakers -------

// Tickets -------
export const Tickets = styled.section``;

export const TicketsWrapper = styled.div`
  border-radius: 10px;
  padding: ${spacing('xl2')};
  padding-right: 450px;
  background: #becffe url('/images/fredcon24/people.svg') no-repeat 100% 100%;
  background-size: 400px;

  @media (max-width: ${screen('lg')}) {
    padding-right: 250px;
    background-size: 250px;
  }

  @media (max-width: ${screen('md')}) {
    background-position: 50% 100%;
    padding-right: ${spacing('xl2')};
    padding-bottom: 200px;
  }

  h1 {
    font-size: 50px;
    margin-top: 0;

    &:after {
      display: none;
    }
  }

  p {
    line-height: 1.75;

    @media (max-width: ${screen('lg')}) {
      line-height: 1.5;
    }
  }
`;

export const TicketsDesc = styled.div``;
// /Tickets -------

// Partners -------
export const Partners = styled.section`
  border-bottom: 2px solid #93a9fd;
`;

export const Partner = styled.a`
  flex: 1 1 23%;
  margin: 1.5rem 0;
`;
// /Partners -------

// Footer -------
export const Footer = styled.footer`
  padding-bottom: 2rem;
`;

export const FooterContact = styled.div`
  font-size: ${fontSize('lg')};
  text-align: center;

  p {
    line-height: 1.75;

    @media (max-width: ${screen('lg')}) {
      line-height: 1.5;
    }

    a {
      color: #1117d7;
      font-weight: bold;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const FooterInfo = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: ${spacing('xl3')};
  margin-top: 4rem;

  h1 {
    font-size: 30px;
    margin-top: 0.25rem;

    :after {
      height: 75px;
      width: 75px;
      background-size: 75px;
      margin-top: -35px;

      @media (max-width: ${screen('md')}) {
        padding-bottom: 75px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  nav {
    font-size: ${fontSize('xs')};
    text-align: center;
    margin-top: 3rem;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;

    @media (max-width: ${screen('md')}) {
      flex-direction: column;
    }

    a {
      font-weight: 700;
      color: #1117d7;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
// /Footer -------
